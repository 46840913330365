













































































import { Component, Prop, Vue } from 'vue-property-decorator'
// @ts-ignore
import { quillEditor } from 'vue-quill-editor'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { createSoundKit, fetchSingleSoundKit, removeSoundKit, updateSoundKit } from '#/api/sound-kits'
import { IModalParams, ModalStore, ModalType } from '~/store/modal'
import { TOOLBAR_SETTINGS } from '#/plugins/vue-quill-editor'
import { UploadStore, UploadType } from '~/store/upload'

enum FileToRemoveOrAdd {
	Image,
	KitFile,
	AudioDemoFile
}

@Component({ components: { VLoadSpinner, quillEditor } })
export default class SoundKitPage extends Vue {
	@Prop({ type: Number }) id: number
	FileToRemoveOrAdd = FileToRemoveOrAdd

	editorOption = { modules: { toolbar: TOOLBAR_SETTINGS } }
	isLoading = true
	existsOnDatabase = false
	// @ts-ignore
	soundKit: ISoundKit = {
		image_url: '',
		audio_demo_file_name: '',
		kit_file_name: ''
	}
	isDropdownOpen = false

	get isNewRoute(): boolean {
		return !this.id
	}

	get drumKitFileNameCleaned(): string {
		if (!this.soundKit.kit_file_name) return ''
		const indexOfLastSlash = this.soundKit.kit_file_name.lastIndexOf('/')
		return this.soundKit.kit_file_name.substring(indexOfLastSlash + 1)
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
	@UploadStore.Action('singleFileUpload') singleFileUpload: (IClientUploadParams: IClientUploadParams) => Promise<IUploadReturn>

	closeDropdown() {
		this.isDropdownOpen = false
	}

	onEditorChange({ html }: { html: string }) {
		this.soundKit.description = html
	}

	async _fetchSoundKit() {
		this.isLoading = true
		try {
			this.soundKit = await fetchSingleSoundKit(this.id)
		} finally {
			this.isLoading = false
		}
	}

	async uploadFile(fileList: FileList, fileToAdd: FileToRemoveOrAdd) {
		try {
			let uploadType = UploadType.Public
			if (fileToAdd === FileToRemoveOrAdd.KitFile) uploadType = UploadType.SoundKit
			if (fileToAdd === FileToRemoveOrAdd.AudioDemoFile) uploadType = UploadType.SoundKitDemo

			const { url, pathToFile } = await this.singleFileUpload({ fileList, uploadType, maxPxSize: uploadType === UploadType.Public ? 500 : undefined })
			switch (fileToAdd) {
				case FileToRemoveOrAdd.Image:
					this.soundKit.image_url = url
					break
				case FileToRemoveOrAdd.KitFile:
					this.soundKit.kit_file_name = pathToFile
					break
				case FileToRemoveOrAdd.AudioDemoFile:
					this.soundKit.audio_demo_file_name = pathToFile
					break
			}
			// if the soundkit was already existing, update it and send api request
			if (this.existsOnDatabase) await updateSoundKit(this.soundKit)
		} catch (err) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	async removeFile(fileToRemove: FileToRemoveOrAdd) {
		try {
			switch (fileToRemove) {
				case FileToRemoveOrAdd.Image:
					this.soundKit.image_url = null
					break
				case FileToRemoveOrAdd.AudioDemoFile:
					this.soundKit.audio_demo_file_name = null
					break
			}
			// if the soundkit was already existing, update it and send api request
			if (this.existsOnDatabase) await updateSoundKit(this.soundKit)
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		}
	}

	// create or updates service
	async saveAllChanges() {
		try {
			if (this.existsOnDatabase) await updateSoundKit(this.soundKit)
			else {
				const newKit = await createSoundKit(this.soundKit)
				Vue.router.push({ name: 'SoundKitEdit', params: { id: Number(newKit.id) } })
			}
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		} finally {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
		}
	}

	async deleteSoundKit() {
		try {
			await removeSoundKit(this.soundKit.id)
		} catch (error) {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
		} finally {
			this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
			Vue.router.push({ name: 'SoundKits' })
		}
	}

	openConfirmActionModal() {
		this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.deleteSoundKit() })
	}

	created() {
		this.existsOnDatabase = !this.isNewRoute
		if (this.existsOnDatabase) this._fetchSoundKit()
		else this.isLoading = false
	}
}
